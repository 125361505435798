body {
  padding-top: 45px;
  color: #1C2C3A;
}
h1,
h2,
h3,
h4,
h5 {
  color: #717171;
}
h1 {
  font-size: 2.625rem;
}
h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.125rem;
}
.navbar-brand {
  padding: 10px 15px;
}
.navbar-default {
  background-color: white;
}
.navbar-default .navbar-nav > li > button {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media(min-width: 768px) {
  .navbar-default .navbar-nav > li > button {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > button {
  color: #3A88FF;
  box-shadow: none;
  text-shadow: none;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > button:hover,
.navbar-default .navbar-nav > li > button:focus {
  background: #f5f5f5;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #1C2C3A;
}
.navbar-default .navbar-toggle {
  border-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #3A88FF;
}
.navbar-default .navbar-nav .dropdown-menu li > a {
  color: #3A88FF;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-default .navbar-nav .dropdown-menu li > a:hover,
.navbar-default .navbar-nav .dropdown-menu li > a:focus,
.navbar-default .navbar-nav .dropdown-menu .active > a {
  color: #1C2C3A;
}
.navbar-default .navbar-nav .dropdown-menu .active > a {
  background-color: #e7e7e7;
}
.page-header {
  position: relative;
  padding: 25px 0;
  margin: 0;
}
.page-header > h1, .access-token {
  margin: 0;
  padding: 0;
}
.page-header .label-environment {
  margin-top: -17px;
  min-width: 105px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.well {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.container-fluid.padded {
  padding: 30px 15px;
}

.table .list-inline {
  margin-bottom: 0;
}

.button_to {
  display: inline-block;
}

.form-control.token[readonly] {
  cursor: inherit;
  background-color: #fff;
}

.marginless {
  margin: 0;
}
.panel {
  border: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
a, .btn-link, .pagination > li > a {
  color: #3A88FF;
}
a:hover, .btn-link:hover, .pagination > li > a:hover {
  color: #1c2c3a;
}
.btn-primary {
  background-color: #3A88FF;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #1c2c3a;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #3A88FF;
}
.btn:active, .btn.active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.panel-body {
  padding: 15px 0;
}
input[type="date"] {
  line-height: 1.5;
}
input[type="file"] {
  height: inherit;
}

/*
 * Form input icons
 */
.right-inner-addon {
  position: relative;
}
.right-inner-addon .form-control {
  padding-right: 30px;
}
.right-inner-addon .glyphicon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 12px;
  pointer-events: none;
}

/*
 * Long lines breaker
 */
.text-break {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/*
 * Labels
 */

.label-sandbox {
  background-color: #f0ad4e;
}

.label-live {
  background-color: #5cb85c;
}

.label-admin {
  background-color: #3a88ff;
}

/*
 * Help spots on input fields
 */
.form-control-feedback-help-spot {
  pointer-events: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 100px;  /* Margin bottom by footer height */
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px; /* Set the fixed height of the footer here */
}

footer > .container {
  border-top: 0.125rem solid rgba(28, 44, 58, 0.1);
  padding-top: 2.25rem;
}

.company-filter .form-group {
  width: 50%
}
.new-company {
  text-align: right;
}

.order-filter .form-group {
  vertical-align: bottom;
  padding-right: 10px;
  padding-bottom: 5px;
  text-align: center;
}

.order-filter label {
  padding: 0 10px;
}
