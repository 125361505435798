.login-layout {
  background: url("../../public/bg.png") center -110px no-repeat #ffede2;
  padding-top: 0;
  width: 100%;
  height: 100%;
}

.login-layout header {
  position: static;
  background: none;
  text-align: center;
  height: auto;
}

.login-layout .logo {
  float: none;
  margin-top: 80px;
  margin-bottom: 20px;
}

.login-container {
  position: relative;
  width: 390px;
  margin: 0 auto 30px auto;
  padding: 30px;
}

.login-layout footer {
  background: transparent;
  text-align: center;
  color: #2B4D64;
}
.login-link {
  color: #6a899e;
  margin-top: 7px;
}
